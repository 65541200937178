<template>
  <div class="schedule">
    <Header
      :isHeader="true"
      :back="true"
      :carInfo="true"
      :mobileResume="true"
      :dealer="true"
      :services="true"
      :mobility="true"
      :wash="true"
      :step="currentStepIndex"
    />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar
          :desktopResume="true"
          :dealer="true"
          :services="true"
          :mobility="true"
          :wash="true"
        />
      </template>
      <Title titleStyle="title" :text="translateString('pickDateHour')" />
      <div class="schedule-details">
        <Calendar
          :availability="availability"
          :selectedTimeDay="
            markingServiceSelected && markingServiceSelected.serviceDate
              ? markingServiceSelected.serviceDate
              : selectedTime.day
          "
          @action="selectDay"
          @month-changed="removeDisabledClass"
        />
        <HoursContainer
          v-if="availableHours.length > 0"
          :data="availableHours"
          :selectedTimeHour="selectedTime.hour"
          :morning="morningHours"
          :afternoon="afternoonHours"
          @action="selectHour"
        />
      </div>
      <template v-slot:footer>
        <Footer>
          <Button
            :label="translateString('continue')"
            :btnClass="ctaBtn"
            class="btn-primary align-btn-schedule"
            @action="next()"
          />
        </Footer>
      </template>
    </Wrapper>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Calendar from "@/components/Calendar/Calendar.vue";
import HoursContainer from "@/components/Calendar/HoursContainer.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Title from "@/components/Title/Title.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";

import { mapGetters, mapState } from "vuex";

import { translateString } from "@/functions/functions.js";

import { country } from "@/services/country";

export default {
  name: "Schedule",
  components: {
    Button,
    Calendar,
    Footer,
    Header,
    HoursContainer,
    Sidebar,
    Title,
    Wrapper,
  },
  data() {
    return {
      ctaBtn: "disabled  ",
      availability: this.$store.state.availability,
      availableHours: [],
      morningHours: [],
      afternoonHours: [],
      daySelected: null,
      serviceDay: null,
      serviceHour: null,
      previousDealer: null,
      userSelectdDate: null,
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    selectedTime() {
      return this.$store.state.availabilitySelected;
    },
    ...mapGetters(["getCurrentStepIndex"]),

    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
    ...mapState(["markingServiceSelected", "dealerSelected"]),
    /*   dealerSelected() {
      return this.$store.state.dealerSelected;
    }, */
  },
  mounted() {
    this.$nextTick(() => {
      const selectedElements = document.querySelectorAll('.selected');
      selectedElements.forEach(element => {
        element.classList.remove('selected');
      });

      // Se isNewAppointment for true, selecione a primeira data disponível
      if (this.$store.state.isNewAppointment && this.availability.length > 0) {
        this.daySelected = this.availability[0].day;
      }
      // Caso contrário, use serviceDate e serviceHour se disponíveis
      else if (this.markingServiceSelected && this.markingServiceSelected.serviceDate && this.dealerSelected.dealerID == this.markingServiceSelected.dealerId) {
        this.serviceDay = this.markingServiceSelected.serviceDate;
        this.daySelected = this.serviceDay;
      } else if (!this.markingServiceSelected && this.availability.length > 0) {
        this.daySelected = this.availability[0].day;
      }

      if (this.markingServiceSelected && this.markingServiceSelected.serviceHour && !this.$store.state.isNewAppointment) {
        this.serviceHour = this.markingServiceSelected.serviceHour;
        this.selectedTime.hour = this.serviceHour ? this.serviceHour : null;
        this.isHourSelected = true;
        this.$store.dispatch("selectOption", {
          selectedValue: "availabilitySelected",
          value: { day: this.selectedTime.day, hour: this.serviceHour },
        });
      }

      if (this.daySelected) {
        const selectedDateElement = document.querySelector(
          `[data-date="${this.daySelected}"]`
        );
        if (selectedDateElement) {
          /* selectedDateElement.classList.remove("disabled");
          selectedDateElement.classList.add("selected"); */
        }
        this.getHours(this.daySelected);
      }
      this.selectDay(this.daySelected);
    });
  },
  created() {
    if (this.availability && this.availability.length > 0) {
      this.selectDay(this.availability[0].day);
    }
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    if (this.$store.state.carPlate == "") {
      this.$router.replace({ name: "Homepage" });
    }

    if (this.selectedTime.day) {
      this.getHours(this.selectedTime.day);
    }
    if (this.selectedTime) {
      this.ctaBtn =
        this.selectedTime.day == "" || this.selectedTime.hour == null ? "disabled " : "";
    }
  },
  methods: {
    translateString,
    selectDay(day) {
      this.daySelected = day;
      const selectedElements = document.querySelectorAll('.selected');
      selectedElements.forEach(element => {
        element.classList.remove('selected');
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "availabilitySelected",
        value: { day: day, hour: this.selectedTime.hour },
      });
      if (this.markingServiceSelected && day === this.serviceDay) {
        this.selectedTime.hour = this.serviceHour;
      } else {
        this.selectedTime.hour = null;
      }
      this.getHours(day);
      // Aqui eu adiciono  a data que vem de This.markingServiceSelected.serviceDate como data disponvel no calendar.
      if (this.markingServiceSelected &&
        this.markingServiceSelected.serviceDate &&
        !this.availability.find(
          (date) => date.day === this.markingServiceSelected.serviceDate
        )
      ) {
        this.availability.push({
          day: this.markingServiceSelected.serviceDate,
          times: [],
        });
      }
      this.persistentDaySelected = this.daySelected;
      this.removeDisabledClass();
    },
    selectHour(event) {

      this.$store.dispatch("selectOption", {
        selectedValue: "availabilitySelected",
        value: { day: this.selectedTime.day, hour: event },
      });

    },
    removeDisabledClass() {
      this.$nextTick(() => {
        if(this.userSelectdDate){
          return;
        }
        let selectedDateElement;
        // If a day has been selected by the user, select it
        if (this.persistentDaySelected) {
          selectedDateElement = document.querySelector(
            `[data-date="${this.persistentDaySelected}"]`
          );
        } else if(this.availability.length > 0) {
          // Otherwise, select the first available date of the new month
          selectedDateElement = document.querySelector(
            `[data-date="${this.availability[0].day}"]`
          );
        }
        if (selectedDateElement) {
          selectedDateElement.classList.remove("disabled");
          selectedDateElement.classList.add("selected");
        }
      });
    },
    next() {
      this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
      this.$router.push({ name: "ContactInfo" });
    },
    getHours(event) {
      //Quando tem hora marcada que vem do serviço, teremos ela como default, e se ainda tiver horario disponivel, ele vai ser o unico disponivel será mostrado tambem.
      let availableHours = [];
      if (event === this.serviceDay) {
        // Se for, adicione a hora padrão à lista de horas disponíveis
        availableHours.push(this.defaultHour);
      }
      if (event === this.serviceDay) {
        this.availableHours = [this.serviceHour];
      } else if (this.availability.length > 0) {
        const dayAvailability = this.availability.find((day) => {
          return day.day == event;
        });
        this.availableHours = dayAvailability ? dayAvailability.times : [];
      } else {
        const interval = this.$store.state.dealerSelected.dealerInterval;
        if(interval == 15) {
          this.availableHours = [ "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45" ];
        } else if(interval == 20) {
          this.availableHours = [ "09:00", "09:20", "09:40", "10:00", "10:20", "10:40", "11:00", "11:20", "11:40", "12:00", "12:20", "12:40", "14:40", "15:00", "15:20", "15:40", "16:00", "16:20", "16:40"];
        } else if(interval == 30) {
          this.availableHours = [ "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "14:30", "15:00", "15:30", "16:00", "16:30" ];
        } else if(interval == 60) {
          this.availableHours = [ "09:00", "10:00", "11:00", "12:00", "15:00", "16:00" ];
        } else {
          if(country === "pt") {
            this.availableHours = [ "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45" ];
          } else {
            this.availableHours = [ "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "14:30", "15:00", "15:30", "16:00", "16:30" ];
          }
        }
      }

      if (this.serviceHour && !this.availableHours.includes(this.serviceHour)) {
        // Se não estiver, adiciono à lista
        this.availableHours.push(this.serviceHour);
      }
      this.morningHours = [];
      this.afternoonHours = [];

      this.availableHours.forEach((hour) => {
        if (hour) {
          const getHour = hour.split(":")[0];
          if (getHour < 14) {
            this.morningHours.push(hour);
          } else {
            this.afternoonHours.push(hour);
          }
        }
      });
    },
  },
  watch: {
    dealerSelected: {
      handler(newDealer, oldDealer) {
        if (newDealer.dealerID !== oldDealer.dealerID) {
          this.daySelected = null;
          this.selectedTime.hour = null;
        }
      },
      deep: true,
    },
    selectedTime: {
      handler() {
        if (this.selectedTime.hour != null) {
          this.ctaBtn =
            this.selectedTime.day == "" || this.selectedTime.hour == ""
              ? "disabled "
              : "";
          this.getHours(this.selectedTime.day);
        } else {
          // Adicione esta verificação para desabilitar o botão
          this.ctaBtn = "disabled ";
        }
      },
      deep: true,
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.schedule-details {
  margin-bottom: 2.5rem;
  @include respond-to-min("md") {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 2rem;

    > * {
      margin: 0;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 360px) {
    height: 33rem;
  }
  //Iphone SE
  @media screen and (min-width: 375px) and (max-width: 375px) {
    height: 33rem;
  }

  //Iphone 12PRO
  @media screen and (min-width: 390px) and (max-width: 411px) {
    height: 33rem;
  }
  //Iphone XR
  @media screen and (min-width: 412px) and (max-width: 420px) {
    height: 33rem;
  }
  @media screen and (min-width: 540px) and (max-width: 540px) {
    height: 33rem;
  }
}
</style>
